.faqItem {
  margin-bottom: 1rem;
  border: 1px solid #f0d0d0;  /* ורוד עתיק בהיר יותר */
  border-radius: 8px;
  overflow: hidden;
  width: 500px;
  margin: 15px auto;
  direction: rtl;
  box-shadow: 0 4px 8px rgba(240, 208, 208, 0.3);  /* צל ורוד עדין */
  text-decoration: none;
  background: linear-gradient(135deg, #fff5f5, #fff);  /* אפקט מראה עדין */
}

.questionButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
  background-color: rgba(255, 240, 240, 0.7);  /* רקע ורוד שקוף קלות */
  border: none;
  text-decoration: none;
  color: #4a4a4a;  /* אפור כהה */
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);  /* אפקט מראה */
}

.questionButton:hover {
  background-color: rgba(255, 230, 230, 0.8);  /* רקע ורוד יותר בהובר */
}

.questionText {
  font-family: 'AssistantR', sans-serif;
  font-size: 1.2rem;
  text-align: center;
  color: #4a4a4a;  /* אפור כהה */
  direction: rtl;
  flex-grow: 1;
}

.iconWrapper {
  width: 24px;
  flex-shrink: 0;
  color: #e0a0a0;  /* ורוד עתיק כהה יותר */
  text-decoration: none;
}

.icon {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
}

.answerContainer {
  overflow: hidden;
  transition: height 0.3s ease;
  background-color: rgba(255, 250, 250, 0.9);  /* רקע ורוד בהיר מאוד */
  margin: auto;
  direction: rtl;
  backdrop-filter: blur(5px);  /* אפקט מראה */
}

.answerText {
  font-family: 'AssistantR', sans-serif;
  font-size: 1.05rem;
  padding: 1rem;
  color: #4a4a4a;  /* אפור כהה */
  justify-content: center;
  text-align: center;
  margin: auto;
}

/* Accent for links or important text within answers */
.answerText a, .answerText strong {
  color: #d88080;  /* ורוד עתיק כהה יותר לדגשים */
}

/* Responsive styles remain unchanged */
@media (max-width: 350px) {
  .faqItem {
    width: 320px;
  }
  .questionText {
    font-size: 0.9rem;
  }
  .answerText {
    font-size: 0.9rem; 
  }
}

@media (min-width: 350px) and (max-width: 450px) {
  .faqItem {
    width: 340px;
  }
  .questionText {
    font-size: 1.05rem;
   
  }
  .answerText {
    font-size: 0.95rem; 
  }
}

@media (min-width: 450px) and (max-width: 550px) {
  .faqItem {
    width: 400px;
  }
  .questionText {
    font-size: 1.1rem;
  }
  .answerText {
    font-size: 1rem; 
  }
}