/* GlassButton.module.css */
.glassButton {
    display: inline-block;
    padding: 10px 20px;
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    color: #ffffff;
    font-family: 'Assistant', sans-serif;
    text-decoration: none;
    width: 200px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    margin: 5px auto;
    transition: all 0.3s ease;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  
  .glassButton:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    height: 300px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    transition: transform 0.6s ease-out, opacity 0.6s ease-out;
  }
  
  .glassButton:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .glassButton:active {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.08);
  }
  
  @keyframes openRipple {
    from {
      transform: translate(-50%, -50%) scale(0);
      opacity: 0;
    }
    to {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }
  
  .glassButton.clicked:before {
    animation: openRipple 0.6s ease-out forwards;
  }