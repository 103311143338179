.center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px;
}

.button {
  background-color: rgb(253, 242, 248); /* רקע ורוד עתיק מאוד מעומעם */
  color: #4a4a4a; /* צבע טקסט כהה יותר לניגודיות */
  border: 2px solid rgba(200, 200, 200, 0.5); /* גבול אפור-לבן */
  border-radius: 10px; /* עיגול קצוות יותר */
  padding: 12px 24px;
  direction: rtl;
  font-family: 'Assistant', sans-serif;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px); /* אפקט זכוכית */
  box-shadow: 
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.08);
}

.button:hover {
  transform: translateY(-2px);
  box-shadow: 
    0 7px 14px rgba(0, 0, 0, 0.1),
    0 3px 6px rgba(0, 0, 0, 0.08);
  background-color: rgb(253, 242, 248); /* רקע מעט פחות שקוף בהובר */
}

.button:active {
  transform: translateY(1px);
  box-shadow: 
    0 2px 4px rgba(0, 0, 0, 0.1),
    0 1px 2px rgba(0, 0, 0, 0.08);
}

/* Responsive styles remain unchanged */
@media (max-width: 350px) {
  .button {
    font-size: 1rem;
    padding: 10px 20px;
  }
}

@media (min-width: 350px) and (max-width: 450px) {
  .button {
    font-size: 1.2rem;
    padding: 11px 22px;
  }
}